import { gql } from "@apollo/client";
import {
  Factoid,
  FactoidBlock,
  HoldCoSubpageMedia,
  Map,
  NetYieldChart,
  SubpageMedia,
} from "components/Markdown";
import Markdown from "markdown-to-jsx";
import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import styled from "styled-components";

import { TimelineSubjectType } from "~/api/types.generated";
import {
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
} from "~/components/common/buttons";
import {
  BoldIcon,
  H1Icon,
  H2Icon,
  InfoIcon,
  ItalicIcon,
  LinkIcon,
  StrikethroughIcon,
  UnderlineIcon,
  UndoIcon,
} from "~/components/common/icons";
import GuideDialog from "~/components/Overview/GuideDialog";
import {
  FundEntityOverviewFragment,
  SubpageOverviewFragment,
} from "~/components/Overview/OverviewPage.generated";
import {
  useCreateTimelineMutation,
  useUpdateSubpageMutation,
} from "~/components/Overview/Subpage.generated";
import { Timeline } from "~/components/Timeline/Timeline";
import { black, yellow } from "~/styles/theme/color";
import { borderRadius } from "~/styles/theme/common";

import SubpageMediaModal from "./SubpageMediaModal";

export const UPDATE_SUBPAGE_MUTATION = gql`
  mutation UpdateSubpage(
    $id: ID!
    $subjectType: String!
    $description: String!
  ) {
    updateSubpage(
      subpageId: $id
      subjectType: $subjectType
      description: $description
    ) {
      subpage {
        ...SubpageOverview
      }
    }
  }
`;

export const CREATE_TIMELINE_MUTATION = gql`
  mutation CreateTimeline($payload: CreateTimelinePayload!) {
    createTimeline(payload: $payload) {
      timeline {
        id
      }
    }
  }
`;

interface SubpageProps {
  subpage: SubpageOverviewFragment;
  onView: (subpage: SubpageOverviewFragment) => void;
  projectsGeoJson: any;
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
  subject: FundEntityOverviewFragment;
}

const Subpage = ({
  subpage,
  onView,
  projectsGeoJson,
  isEditing,
  setIsEditing,
  subject,
}: SubpageProps) => {
  const [description, setDescription] = useState(subpage.description);
  const [originalDescription, setOriginalDescription] = useState(
    subpage.description
  );
  const [undo, setUndo] = useState<string | null>(null);
  const [undoOffset, setUndoOffset] = useState<number>(0);
  const [updateSubpage] = useUpdateSubpageMutation();
  const [createTimeline] = useCreateTimelineMutation();
  const [showMediaModal, setShowMediaModal] = useState(false);
  const [showGuideDialog, setShowGuideDialog] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => onView(subpage), [onView, subpage]);

  const handleSave = async () => {
    try {
      await updateSubpage({
        variables: {
          id: subpage.id,
          subjectType: subject.__typename,
          description,
        },
      });
      setOriginalDescription(description);
      setUndo(null);
      setUndoOffset(0);
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating subpage:", error);
    }
  };

  const handleCancel = () => {
    setDescription(originalDescription);
    setIsEditing(false);
  };

  const handleUndoLast = () => {
    if (undo === null) return;
    const lastState = undo;
    const cursorOffset = undoOffset;
    const currentText = description;

    // Save the current cursor position
    const cursorPosition = textareaRef.current?.selectionStart || 0;

    // Calculate the new cursor position relative to the removed text
    const newCursorPosition =
      cursorPosition - (currentText.length - lastState.length) + cursorOffset;

    setUndo(null); // Update the stack
    setUndoOffset(0); // Update the stack
    setDescription(lastState); // Restore the last state

    // Keep the textarea focused and restore the adjusted cursor position
    setTimeout(() => {
      if (textareaRef.current) {
        textareaRef.current.focus();
        textareaRef.current.selectionStart = textareaRef.current.selectionEnd =
          newCursorPosition;
      }
    }, 0);
  };

  const insertAtCursor = (
    componentString: string,
    cursorOffset: number = 0
  ) => {
    if (textareaRef.current) {
      const { selectionStart, selectionEnd } = textareaRef.current;
      const newText =
        description.slice(0, selectionStart) +
        componentString +
        description.slice(selectionEnd);

      // Push the current state to the undo stack (currently only 1 undo is saved)
      setUndo(description);
      setUndoOffset(cursorOffset);

      setDescription(newText);
      setTimeout(() => {
        textareaRef.current!.selectionStart =
          textareaRef.current!.selectionEnd =
            selectionStart + componentString.length;
        textareaRef.current!.focus();
      }, 0);
    }
  };

  const handleInsertComponent = (componentString: string) => {
    insertAtCursor(componentString);
  };

  const handleInsertFont = (prefix: string, postfix: string) => {
    if (textareaRef.current) {
      const { selectionStart, selectionEnd } = textareaRef.current;

      if (selectionStart !== selectionEnd) {
        // Text is highlighted
        const selectedText = description.slice(selectionStart, selectionEnd);
        const newText =
          description.slice(0, selectionStart) +
          prefix +
          selectedText +
          postfix +
          description.slice(selectionEnd);

        // We won't push this to the undo stack
        setUndo(description);
        setUndoOffset(prefix.length + postfix.length);
        setDescription(newText);

        setTimeout(() => {
          textareaRef.current!.selectionStart = selectionStart;
          textareaRef.current!.selectionEnd =
            selectionEnd + prefix.length + postfix.length;
          textareaRef.current!.focus();
        }, 0);
      } else {
        // No text is highlighted, just a cursor
        const componentString = `${prefix}${postfix}`;
        insertAtCursor(componentString, postfix.length);

        setTimeout(() => {
          textareaRef.current!.selectionStart =
            textareaRef.current!.selectionEnd = selectionStart + prefix.length;
          textareaRef.current!.focus();
        }, 0);
      }
    }
  };

  const handleInsertSubpageMedia = (id: string) => {
    insertAtCursor(`<SubpageMedia id="${id}"/>`);
    setShowMediaModal(false);
  };

  const handleInsertTimeline = async () => {
    try {
      const subjectType =
        subject.__typename === "Portfolio"
          ? TimelineSubjectType.Portfolio
          : TimelineSubjectType.HoldingCompany;

      const { data } = await createTimeline({
        variables: {
          payload: {
            subjectType,
            subjectId: subject.id,
          },
        },
      });

      const timelineId = data?.createTimeline?.timeline?.id;
      const componentString = `<Timeline id="${timelineId}" />`;

      insertAtCursor(componentString);
    } catch (error) {
      console.error("Error creating timeline:", error);
    }
  };

  return (
    <div>
      {isEditing ? (
        <div>
          <EditingModeHeader>
            Editing Mode
            <InfoIcon onClick={() => setShowGuideDialog(true)} />
          </EditingModeHeader>
          <textarea
            ref={textareaRef}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            rows={10}
            className="form-control"
          />
          <FlexContainer>
            <ButtonGroup>
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  Insert Component
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => handleInsertComponent("<Map />")}
                  >
                    Map
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setShowMediaModal(true)}>
                    Image
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handleInsertTimeline}>
                    Timeline
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <FontTertiaryButton
                onClick={handleUndoLast}
                disabled={undo === null} // Disable if no undo actions available
              >
                <UndoIcon />
              </FontTertiaryButton>
              <FontTertiaryButton
                onClick={() => handleInsertFont("\n# ", "\n")}
              >
                <H1Icon />
              </FontTertiaryButton>
              <FontTertiaryButton
                onClick={() => handleInsertFont("\n## ", "\n")}
              >
                <H2Icon />
              </FontTertiaryButton>
              <FontTertiaryButton
                onClick={() => handleInsertFont("\n\n---\n\n", "")}
              >
                <span>&mdash;</span>
              </FontTertiaryButton>
              <FontTertiaryButton onClick={() => handleInsertFont("**", "**")}>
                <BoldIcon />
              </FontTertiaryButton>
              <FontTertiaryButton onClick={() => handleInsertFont("*", "*")}>
                <ItalicIcon />
              </FontTertiaryButton>
              <FontTertiaryButton
                onClick={() => handleInsertFont("<ins>", "</ins>")}
              >
                <UnderlineIcon />
              </FontTertiaryButton>
              <FontTertiaryButton onClick={() => handleInsertFont("~~", "~~")}>
                <StrikethroughIcon />
              </FontTertiaryButton>
              <FontTertiaryButton
                onClick={() => handleInsertFont("[Link](", ")")}
              >
                <LinkIcon />
              </FontTertiaryButton>
            </ButtonGroup>
            <ButtonGroup>
              <PrimaryButton onClick={handleSave}>Save</PrimaryButton>
              <SecondaryButton onClick={handleCancel}>Cancel</SecondaryButton>
            </ButtonGroup>
          </FlexContainer>
          <SubpageMediaModal
            show={showMediaModal}
            onHide={() => setShowMediaModal(false)}
            onSelect={handleInsertSubpageMedia}
            subpageId={subpage.id}
            subjectId={subject.id}
            subjectType={subject.__typename}
          />
          <GuideDialog
            show={showGuideDialog}
            onHide={() => setShowGuideDialog(false)}
          />
        </div>
      ) : (
        <div>
          <Markdown
            options={{
              overrides: {
                NetYieldChart: {
                  component: NetYieldChart,
                },
                SubpageMedia: {
                  component: SubpageMedia,
                },
                HoldCoSubpageMedia: {
                  component: HoldCoSubpageMedia,
                },
                FactoidBlock: {
                  component: FactoidBlock,
                },
                Factoid: {
                  component: Factoid,
                },
                Timeline: {
                  component: Timeline,
                },
                Map: {
                  component: Map,
                  props: {
                    projectsGeoJson,
                  },
                },
              },
            }}
          >
            {description}
          </Markdown>
        </div>
      )}
    </div>
  );
};

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const EditingModeHeader = styled.div`
  display: flex;
  align-items: center;
  background-color: ${yellow};
  border-radius: ${borderRadius};
  padding: 10px;
  margin-bottom: 10px;
  font-size: 1.125rem;
  font-weight: 600;

  & > svg {
    font-size: 1.25rem; /* Target the InfoIcon */
    margin-left: 5px; /* Example styling */
    cursor: pointer;
  }
`;

const FontTertiaryButton = styled(TertiaryButton)`
  border: 1px solid ${black}; /* Add a border */
  padding: 5px 10px; /* Optional padding for better appearance */

  &:hover {
    background-color: #333; /* Dark background on hover */
    color: #fff; /* Light text color */

    & > svg {
      fill: #fff; /* Light color for SVG on hover */
    }
  }

  & > svg {
    transition: fill 0.3s ease; /* Smooth transition for SVG color */
  }
`;

export default Subpage;
