import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import React from "react";

const H1Icon = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox={"0 -960 960 960"}
    height="24px"
    width="24px"
    fill="currentColor"
  >
    <path d="M200-280v-400h80v160h160v-160h80v400h-80v-160H280v160h-80Zm480 0v-320h-80v-80h160v400h-80Z" />
  </SvgIcon>
);

export default H1Icon;
