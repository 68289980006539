import { gql } from "@apollo/client";
import HoldcoStats from "components/HoldingCompany/HoldcoStats";
import React, { useMemo } from "react";
import { BrowserRouter, useParams, useRouteMatch } from "react-router-dom";

import { PermissionType } from "~/api/types.generated";
import { PageContainer } from "~/components/common/layout";
import {
  HOLDING_COMPANY_BODY_FRAGMENT,
  HoldingCompanyBody,
} from "~/components/HoldingCompany/HoldingCompanyBody";
import { useHoldingCompanyPageQuery } from "~/components/HoldingCompany/HoldingCompanyPage.generated";
import {
  HOLDING_COMPANY_TABS_FRAGMENT,
  HoldingCompanyTabs,
} from "~/components/HoldingCompany/HoldingCompanyTabs";
import { FUND_ENTITY_OVERVIEW_FRAGMENT } from "~/components/Overview/OverviewPage";
import { EventTrackingProvider } from "~/event-tracking/EventTrackingProvider";
import { FooterViewerFragment } from "~/Footer.generated";
import {
  OrgFeatureFlags,
  SubjectPermission,
} from "~/permissions/useViewerPermissions";

export const HOLDING_COMPANY_PAGE_QUERY = gql`
  query HoldingCompanyPage($slug: String!) {
    holdingCompany(slug: $slug) {
      id
      documentsTree {
        id
      }
      reportsTree {
        id
      }
      ...FundEntityOverview
      ...HoldingCompanyBody
      ...HoldingCompanyTabs
    }
  }
  ${FUND_ENTITY_OVERVIEW_FRAGMENT}
  ${HOLDING_COMPANY_TABS_FRAGMENT}
  ${HOLDING_COMPANY_BODY_FRAGMENT}
`;

interface HoldingCompanyPageProps {
  holdingCompanyPermissions: SubjectPermission;
  viewer: FooterViewerFragment;
  featureFlagsByOrg: OrgFeatureFlags;
}

export const HoldingCompanyPage = ({
  holdingCompanyPermissions,
  viewer,
  featureFlagsByOrg,
}: HoldingCompanyPageProps) => {
  const { slug } = useParams<{ slug: string }>();
  const match = useRouteMatch();
  const baseUrl = match.path.replace(":slug", slug);
  const { data } = useHoldingCompanyPageQuery({
    variables: { slug: slug },
  });
  const permissions =
    holdingCompanyPermissions[data?.holdingCompany?.id ?? ""] ?? [];
  const isAdmin =
    !!viewer.isSuperUser && permissions.includes(PermissionType.OrgAdmin);
  const isUsersAdmin = permissions.includes(PermissionType.UsersAdmin);
  const isQAndAViewer = permissions.includes(PermissionType.QuestionsView);
  const isDocumentsAdmin = permissions.includes(PermissionType.DocumentsAdmin);
  const isDealRoomAdmin = permissions.includes(PermissionType.DealroomAdmin);
  const areLabelsEnabled = !!(
    featureFlagsByOrg[data?.holdingCompany?.org.id || ""] ?? {}
  ).documentLabels;
  const trackingContext = useMemo(
    () => ({
      "Holding Company Slug": slug,
      "Holding Company ID": data?.holdingCompany?.id,
      "Holding Company Name": data?.holdingCompany?.name,
    }),
    [slug, data?.holdingCompany?.id, data?.holdingCompany?.name]
  );
  return (
    <BrowserRouter basename={baseUrl}>
      <PageContainer>
        <EventTrackingProvider value={trackingContext}>
          {data?.holdingCompany && (
            <HoldcoStats
              holdco={data?.holdingCompany}
              showAdmin={isAdmin}
              showUsersGroups={isUsersAdmin}
            />
          )}
          {data?.holdingCompany && (
            <HoldingCompanyTabs
              holdco={data.holdingCompany}
              canViewQAndA={isQAndAViewer}
            />
          )}
          {data?.holdingCompany && (
            <HoldingCompanyBody
              holdco={data.holdingCompany}
              isSuperUser={!!viewer.isSuperUser}
              isUsersAdmin={isUsersAdmin}
              isDocumentsAdmin={isDocumentsAdmin}
              isDealRoomAdmin={isDealRoomAdmin}
              areLabelsEnabled={areLabelsEnabled}
              canViewDocumentsHistory={permissions.includes(
                PermissionType.DocumentsHistoryView
              )}
              canViewQAndA={isQAndAViewer}
              isQAndAAdmin={permissions.includes(
                PermissionType.QuestionLogAdmin
              )}
              canEditQuestions={permissions.includes(
                PermissionType.QuestionsEdit
              )}
              canEditQuestionAnswers={permissions.includes(
                PermissionType.QuestionAnswersEdit
              )}
            />
          )}
        </EventTrackingProvider>
      </PageContainer>
    </BrowserRouter>
  );
};
